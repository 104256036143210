<template>
  <div class="logo-container App">
    <img src="@/assets/rbla.png" alt="Logo" class="imgh" style="width: 100vw; height: 130px;" />
  </div>
</template>

<script>
export default {
  name: 'HeadPage',
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
