// firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database"; 
const firebaseConfig = {
  apiKey: "AIzaSyDtvjwnKQtl2bAJsdfF9ietfN9wpyQSUxc",
  authDomain: "uvyb-e2970.firebaseapp.com",
  databaseURL: "https://uvyb-e2970-default-rtdb.firebaseio.com",
  projectId: "uvyb-e2970",
  storageBucket: "uvyb-e2970.appspot.com",
  messagingSenderId: "495848336920",
  appId: "1:495848336920:web:516426dbfff9b57828dd6d",
  measurementId: "G-WXEW8TKWC1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app); 

// Export the initialized Firebase app
export { app, db };
