<template>
  <div class="App">
    <div class="logo-container">
      <img src="@/assets/rblb.png" alt="Footer Image" class="imga" style="width: 100vw; height: 310px;" />
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'FooterPage',
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
